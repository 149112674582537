<script>
import { BFormInput, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueSelect from 'vue-select'

const TAX_ID_TYPES = [
  {
    type: 'es_cif',
    country: 'ES',
    format: '[0-9A-Z][0-9]{7}[0-9A-Z]',
    placeholder: 'A12345678',
    description: 'Spanish NIF number (previously Spanish CIF number)',
    flag: '🇪🇸',
  },
  {
    type: 'es_vat',
    country: 'ES',
    format: 'ES([A-Z][0-9]{8}|[0-9]{8}[A-Z]|[A-Z][0-9]{7}[A-Z])',
    placeholder: 'ESA1234567Z',
    description: 'European VAT number',
    flag: '🇪🇸',
  },
]

export default {
  name: 'TaxIDField',
  components: {
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    VueSelect,
  },
  props: ['tax_id', 'tax_id_type', 'value'],
  data() {
    return {
      taxIDNumber: this.value.tax_id,
      taxIDType: TAX_ID_TYPES.find(t => t.type === this.value.tax_id_type),
      errors: {
        noTaxIDType: '',
        invalidTaxIDNumber: '',
      },
      taxIDTypes: TAX_ID_TYPES,
    }
  },
  computed: {
    taxIDNumberPlaceholder() {
      return this.taxIDType?.placeholder
    },
  },
  methods: {
    handleTypeChange(value) {
      this.errors.noTaxIDType = ''
      this.taxIDNumber = ''
      this.taxIDType = value
      this.$emit('change', { tax_id: '', tax_id_type: value.type })
      this.$emit('input', { tax_id: '', tax_id_type: value.type })
    },
    handleNumberChange(value) {
      if (this.taxIDType) {
        const validationRegex = new RegExp(`${this.taxIDType.format}$`)
        if (validationRegex.test(value)) {
          this.errors.invalidTaxIDNumber = ''
          this.$emit('change', { tax_id: value, tax_id_type: this.taxIDType.type })
          this.$emit('input', { tax_id: value, tax_id_type: this.taxIDType.type })
        } else {
          this.errors.invalidTaxIDNumber = this.$t('Invalid tax ID number')
        }
      } else {
        this.errors.noTaxIDType = this.$t('Please select a tax ID  first')
      }
      this.taxIDNumber = value
    },
    getType(type) {
      return type
        .split('_')
        .map(x => x.toUpperCase())
        .join(' ')
    },
    getCountry(country) {
      return this.$t(`country_${country}`)
    },
  },
}
</script>

<template>
  <b-form-group label-for="tax_number" :label="$t('Tax ID')">
    <div class="tax-id">
      <vue-select
        label="description"
        :options="taxIDTypes"
        :value="taxIDType"
        :clearable="false"
        :class="errors.noTaxIDType ? 'border-danger-for-v-select' : null"
        @input="handleTypeChange"
      >
        <template #option="{ flag, type, country }">
          <div class="tax-id-option">
            <span>{{ flag }}</span>
            <span>{{ getType(type) }}</span>
            <span>{{ getCountry(country) }}</span>
          </div>
        </template>

        <template #selected-option="{ flag, type }">
          <div class="tax-id-option">
            <span>{{ flag }}</span>
            <span>{{ getType(type) }}</span>
          </div>
        </template>
      </vue-select>
      <b-form-input
        id="tax_number"
        name="tax_number"
        autocomplete="off"
        :placeholder="taxIDNumberPlaceholder"
        :value="taxIDNumber"
        :state="errors.noTaxIDType || errors.invalidTaxIDNumber ? false : null"
        @input="handleNumberChange"
      ></b-form-input>
    </div>
    <small v-for="error in errors" class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<style scoped>
.tax-id {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.tax-id-option {
  display: grid;
  grid-template-columns: minmax(0, 16px) minmax(0, 48px) minmax(0, 1fr);
  gap: 12px;
}

::v-deep .v-select {
  min-width: 180px;
}

::v-deep .vs__dropdown-option--selected::after {
  display: none;
}
</style>
