var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"label-for":"tax_number","label":_vm.$t('Tax ID')}},[_c('div',{staticClass:"tax-id"},[_c('vue-select',{class:_vm.errors.noTaxIDType ? 'border-danger-for-v-select' : null,attrs:{"label":"description","options":_vm.taxIDTypes,"value":_vm.taxIDType,"clearable":false},on:{"input":_vm.handleTypeChange},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var flag = ref.flag;
var type = ref.type;
var country = ref.country;
return [_c('div',{staticClass:"tax-id-option"},[_c('span',[_vm._v(_vm._s(flag))]),_c('span',[_vm._v(_vm._s(_vm.getType(type)))]),_c('span',[_vm._v(_vm._s(_vm.getCountry(country)))])])]}},{key:"selected-option",fn:function(ref){
var flag = ref.flag;
var type = ref.type;
return [_c('div',{staticClass:"tax-id-option"},[_c('span',[_vm._v(_vm._s(flag))]),_c('span',[_vm._v(_vm._s(_vm.getType(type)))])])]}}])}),_c('b-form-input',{attrs:{"id":"tax_number","name":"tax_number","autocomplete":"off","placeholder":_vm.taxIDNumberPlaceholder,"value":_vm.taxIDNumber,"state":_vm.errors.noTaxIDType || _vm.errors.invalidTaxIDNumber ? false : null},on:{"input":_vm.handleNumberChange}})],1),_vm._l((_vm.errors),function(error){return _c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(error))])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }